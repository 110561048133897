<template>
  <div v-if="data?.list">
    <h1 class="text-2xl font-semibold text-gray-900 mb-8">
      Passagier saldo - Overzicht
    </h1>

    <UITableResponsive
      :items="data.list || []"
      class="text-center w-auto"
      :headers="['Rit_ID', 'Gegevens', 'Datums', 'Saldo']"
      :suffix-headers="['Acties']"
    >
      <template #colgroup>
        <colgroup>
          <col class="w-0">
          <col class="w-0">
          <col class="w-0">
          <col class="w-auto">
          <col class="w-0">
        </colgroup>
      </template>
      <template #item-Rit_ID="{ item }">
        <ButtonRitInfo :id="item.id" target="_blank" />
      </template>
      <template #item-Gegevens="{ item }">
        <div class="flex flex-col gap-2 pr-4">
          <KlantLijn
            class="card gray"
            label="Klant"
            :klant="item.klant"
          />
          <KlantLijn
            class="card gray"
            label="Reiziger"
            :klant="item.reiziger"
          />
        </div>
      </template>
      <template #item-Datums="{ item }">
        <div class="flex flex-col gap-2 pr-8">
          <div class="flex items-center gap-2">
            <span>{{ dateWrittenTimeString(item.vertrek_datetime) }}</span>
            <label class="label-info ml-0 text-xs">
              <i class="fas fa-users text-base"></i> {{ item.personen_heen || '?' }}
            </label>
          </div>
          <div v-if="item.is_retour" class="flex items-center gap-2">
            <span>{{ dateWrittenTimeString(item.retour_datetime) }}</span>
            <label class="label-info ml-0 text-xs">
              <i class="fas fa-users text-base"></i> {{ item.personen_retour || '?' }}
            </label>
          </div>
          <span v-else class="text-red-500 font-bold">Enkele rit!</span>
        </div>
      </template>
      <template #item-Saldo="{ item }">
        <span>&euro;&nbsp;{{ item.passagier_saldo.toFixed(2) }}</span>
      </template>
      <template #item-Acties="{ item }">
        <div class="flex flex-col gap-2">
          <button :disabled="item.is_behandeld" class="ml-1 btn success" @click="handleBehandeld(item, true)">
            {{ item.is_behandeld ? 'Behandeld!' : 'Markeer als behandeld' }}
          </button>
          <button v-if="item.is_behandeld" class="ml-1 btn error" @click="handleBehandeld(item, false)">
            Markeer als onbehandeld
          </button>
        </div>
      </template>
    </UITableResponsive>
    <UITablePagination
      v-model:limit="limit"
      v-model:offset="offset"
      class="mt-2"
      :count="data?.count || 0"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'

import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'
import UITablePagination from '@/components/UI/Table/Pagination.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { dateWrittenTimeString } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'

const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))

const api = useApi()
const limit = ref(25)
const offset = ref(0)
const apiData = computed(() => ({ limit: limit.value, offset: offset.value }))

const { data } = useGetApi('FINANCIAL_PASSAGIER_SALDO_LIST', apiData, { watch: true })

const handleBehandeld = async (item, is_behandeld) => {
  await api.post('FINANCIAL_PASSAGIER_SALDO_BEHANDELD', { rit_id: item.id, is_behandeld }).then(() => {
    item.is_behandeld = is_behandeld
  })
}

// const handleOpslaan = async (item) => {
//   console.log('Opslaan', item)
// }
</script>
